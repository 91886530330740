/* @author Jayden Scahill || Old Mate Developments */

import React, { useState } from 'react';
import { Trash2, Edit2, Paperclip, X, Copy, Calendar } from 'lucide-react';
import { useServiceStore } from '../../store/serviceStore';
import { formatDate } from '../../utils/date';
import { ServiceForm } from './ServiceForm';
import { ConfirmDialog } from '../ui/ConfirmDialog';
import { addToCalendar } from '../../utils/calendar';
import type { ServiceRecord, Vehicle } from '../../types';

interface ServiceCardProps {
  service: ServiceRecord;
  vehicle?: Vehicle;
}

export function ServiceCard({ service, vehicle }: ServiceCardProps) {
  const { deleteService, updateService, addService } = useServiceStore();
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showDeleteAttachConfirm, setShowDeleteAttachConfirm] = useState<string | null>(null);

  const truncateFileName = (name: string, maxLength: number = 20) => {
    if (name.length <= maxLength) return name;
    const extension = name.split('.').pop();
    const nameWithoutExt = name.substring(0, name.lastIndexOf('.'));
    const truncated = nameWithoutExt.substring(0, maxLength - 3) + '...';
    return `${truncated}.${extension}`;
  };

  const handleDeleteAttachment = async (attachmentId: string) => {
    const updatedAttachments = service.attachments.filter(
      attachment => attachment.id !== attachmentId
    );
    
    await updateService(service.id, {
      ...service,
      attachments: updatedAttachments
    });
    setShowDeleteAttachConfirm(null);
  };

  const handleDuplicate = async () => {
    const duplicatedService: Omit<ServiceRecord, 'id'> = {
      vehicleId: service.vehicleId,
      date: new Date(),
      type: service.type,
      description: service.description,
      mileage: service.mileage,
      cost: service.cost,
      location: service.location,
      nextServiceDate: new Date(),
      attachments: []
    };

    await addService(duplicatedService);
  };

  const handleAddToCalendar = () => {
    const title = `Vehicle Service Due: ${vehicle ? `${vehicle.year} ${vehicle.make} ${vehicle.model}` : 'Unknown Vehicle'}`;
    const description = `Service Type: ${service.type}\nDescription: ${service.description}\nLocation: ${service.location}`;
    addToCalendar(title, description, service.nextServiceDate);
  };

  if (isEditing) {
    return (
      <ServiceForm
        service={service}
        onComplete={() => setIsEditing(false)}
        isEditing
      />
    );
  }

  return (
    <>
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
        <div className="flex justify-between items-start">
          <div>
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
              {vehicle ? `${vehicle.year} ${vehicle.make} ${vehicle.model}` : 'Unknown Vehicle'}
            </h3>
            <p className="text-sm text-gray-500 dark:text-gray-400">{service.type}</p>
          </div>
          <div className="flex space-x-2">
            <button
              onClick={handleAddToCalendar}
              className="text-blue-500 hover:text-blue-600"
              aria-label="Add to calendar"
              title="Add to calendar"
            >
              <Calendar className="w-5 h-5" />
            </button>
            <button
              onClick={handleDuplicate}
              className="text-blue-500 hover:text-blue-600"
              aria-label="Duplicate service record"
            >
              <Copy className="w-5 h-5" />
            </button>
            <button
              onClick={() => setIsEditing(true)}
              className="text-blue-500 hover:text-blue-600"
              aria-label="Edit service record"
            >
              <Edit2 className="w-5 h-5" />
            </button>
            <button
              onClick={() => setShowDeleteConfirm(true)}
              className="text-red-500 hover:text-red-600"
              aria-label="Delete service record"
            >
              <Trash2 className="w-5 h-5" />
            </button>
          </div>
        </div>

        <div className="mt-4 grid grid-cols-2 gap-4">
          <div>
            <p className="text-sm text-gray-600 dark:text-gray-300">
              <span className="font-medium">Date:</span> {formatDate(service.date)}
            </p>
            <p className="text-sm text-gray-600 dark:text-gray-300">
              <span className="font-medium">Kilometers/Hours:</span> {service.mileage.toLocaleString()}
            </p>
            <p className="text-sm text-gray-600 dark:text-gray-300">
              <span className="font-medium">Cost:</span> ${service.cost.toFixed(2)}
            </p>
          </div>
          <div>
            <p className="text-sm text-gray-600 dark:text-gray-300">
              <span className="font-medium">Next Service:</span> {formatDate(service.nextServiceDate)}
            </p>
            <p className="text-sm text-gray-600 dark:text-gray-300">
              <span className="font-medium">Description:</span> {service.description}
            </p>
          </div>
        </div>

        {service.attachments.length > 0 && (
          <div className="mt-4">
            <p className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Attachments ({service.attachments.length}):
            </p>
            <div className="flex flex-wrap gap-2">
              {service.attachments.map((attachment) => (
                <div
                  key={attachment.id}
                  className="group relative inline-flex items-center px-2 py-1 text-sm bg-gray-100 dark:bg-gray-700 text-blue-600 dark:text-blue-400 rounded hover:bg-gray-200 dark:hover:bg-gray-600"
                >
                  <Paperclip className="w-4 h-4 mr-1" />
                  <a
                    href={attachment.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="truncate max-w-[150px]"
                  >
                    {truncateFileName(attachment.name)}
                  </a>
                  <button
                    onClick={() => setShowDeleteAttachConfirm(attachment.id)}
                    className="ml-2 p-1 rounded-full hover:bg-gray-300 dark:hover:bg-gray-500"
                    aria-label="Delete attachment"
                  >
                    <X className="w-3 h-3" />
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      <ConfirmDialog
        isOpen={showDeleteConfirm}
        onClose={() => setShowDeleteConfirm(false)}
        onConfirm={() => deleteService(service.id)}
        title="Delete Service Record"
        message="Are you sure you want to delete this service record? This action cannot be undone."
      />

      <ConfirmDialog
        isOpen={!!showDeleteAttachConfirm}
        onClose={() => setShowDeleteAttachConfirm(null)}
        onConfirm={() => showDeleteAttachConfirm && handleDeleteAttachment(showDeleteAttachConfirm)}
        title="Delete Attachment"
        message="Are you sure you want to delete this attachment? This action cannot be undone."
      />
    </>
  );
}