/* @author Jayden Scahill || Old Mate Developments */

import React, { useState } from 'react';
import type { PersonalInfo } from '../../types/personal';
import { FormSection } from './FormSection';
import { InputField } from './InputField';

interface PersonalInfoFormProps {
  initialData?: PersonalInfo | null;
  onSubmit: (data: PersonalInfo) => void;
  onCancel?: () => void;
}

export function PersonalInfoForm({ initialData, onSubmit, onCancel }: PersonalInfoFormProps) {
  const [formData, setFormData] = useState<Partial<PersonalInfo>>(initialData || {});

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    const personalInfo: PersonalInfo = {
      id: initialData?.id || crypto.randomUUID(),
      firstName: formData.firstName || '',
      lastName: formData.lastName || '',
      email: formData.email || '',
      phone: formData.phone || '',
      address: {
        street: formData.address?.street || '',
        city: formData.address?.city || '',
        state: formData.address?.state || '',
        postalCode: formData.address?.postalCode || '',
        country: formData.address?.country || '',
      },
      emergencyContact: {
        name: formData.emergencyContact?.name || '',
        relationship: formData.emergencyContact?.relationship || '',
        phone: formData.emergencyContact?.phone || '',
      },
      driverLicense: {
        number: formData.driverLicense?.number || '',
        expiryDate: formData.driverLicense?.expiryDate || '',
        state: formData.driverLicense?.state || '',
      },
      insurance: {
        provider: formData.insurance?.provider || '',
        policyNumber: formData.insurance?.policyNumber || '',
        expiryDate: formData.insurance?.expiryDate || '',
      },
    };

    onSubmit(personalInfo);
  };

  const updateFormData = (section: string, field: string, value: string) => {
    setFormData(prev => ({
      ...prev,
      [section]: {
        ...prev[section as keyof PersonalInfo],
        [field]: value
      }
    }));
  };

  return (
    <form onSubmit={handleSubmit} className="form-container p-6">
      <div className="space-y-6">
        <FormSection title="Basic Information">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <InputField
              label="First Name *"
              value={formData.firstName || ''}
              onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
              required
            />
            <InputField
              label="Last Name *"
              value={formData.lastName || ''}
              onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
              required
            />
            <InputField
              label="Email *"
              type="email"
              value={formData.email || ''}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              required
            />
            <InputField
              label="Phone *"
              type="tel"
              value={formData.phone || ''}
              onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
              required
            />
          </div>
        </FormSection>

        <FormSection title="Address">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="md:col-span-2">
              <InputField
                label="Street Address"
                value={formData.address?.street || ''}
                onChange={(e) => updateFormData('address', 'street', e.target.value)}
              />
            </div>
            <InputField
              label="City"
              value={formData.address?.city || ''}
              onChange={(e) => updateFormData('address', 'city', e.target.value)}
            />
            <InputField
              label="State"
              value={formData.address?.state || ''}
              onChange={(e) => updateFormData('address', 'state', e.target.value)}
            />
            <InputField
              label="Postal Code"
              value={formData.address?.postalCode || ''}
              onChange={(e) => updateFormData('address', 'postalCode', e.target.value)}
            />
            <InputField
              label="Country"
              value={formData.address?.country || ''}
              onChange={(e) => updateFormData('address', 'country', e.target.value)}
            />
          </div>
        </FormSection>

        <FormSection title="Emergency Contact">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <InputField
              label="Name"
              value={formData.emergencyContact?.name || ''}
              onChange={(e) => updateFormData('emergencyContact', 'name', e.target.value)}
            />
            <InputField
              label="Relationship"
              value={formData.emergencyContact?.relationship || ''}
              onChange={(e) => updateFormData('emergencyContact', 'relationship', e.target.value)}
            />
            <InputField
              label="Phone"
              type="tel"
              value={formData.emergencyContact?.phone || ''}
              onChange={(e) => updateFormData('emergencyContact', 'phone', e.target.value)}
            />
          </div>
        </FormSection>

        <FormSection title="Driver's License">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <InputField
              label="License Number"
              value={formData.driverLicense?.number || ''}
              onChange={(e) => updateFormData('driverLicense', 'number', e.target.value)}
            />
            <InputField
              label="State"
              value={formData.driverLicense?.state || ''}
              onChange={(e) => updateFormData('driverLicense', 'state', e.target.value)}
            />
            <InputField
              label="Expiry Date"
              type="date"
              value={formData.driverLicense?.expiryDate || ''}
              onChange={(e) => updateFormData('driverLicense', 'expiryDate', e.target.value)}
            />
          </div>
        </FormSection>

        <FormSection title="Insurance Information">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <InputField
              label="Insurance Provider"
              value={formData.insurance?.provider || ''}
              onChange={(e) => updateFormData('insurance', 'provider', e.target.value)}
            />
            <InputField
              label="Policy Number"
              value={formData.insurance?.policyNumber || ''}
              onChange={(e) => updateFormData('insurance', 'policyNumber', e.target.value)}
            />
            <InputField
              label="Expiry Date"
              type="date"
              value={formData.insurance?.expiryDate || ''}
              onChange={(e) => updateFormData('insurance', 'expiryDate', e.target.value)}
            />
          </div>
        </FormSection>
      </div>

      <div className="mt-6 flex justify-end gap-4">
        {onCancel && (
          <button
            type="button"
            onClick={onCancel}
            className="px-4 py-2 text-gray-700 dark:text-gray-200 border border-gray-300 dark:border-gray-600 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700"
          >
            Cancel
          </button>
        )}
        <button
          type="submit"
          className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
        >
          {initialData ? 'Save Changes' : 'Save Information'}
        </button>
      </div>
    </form>
  );
}