/* @author Jayden Scahill || Old Mate Developments */

import { create } from 'zustand';
import { supabase } from '../lib/supabase';
import type { ServiceRecord } from '../types';
import type { Database } from '../types/database';

type ServiceRecordRow = Database['public']['Tables']['service_records']['Row'];

const mapServiceFromDB = (service: ServiceRecordRow): ServiceRecord => ({
  id: service.id,
  vehicleId: service.vehicle_id,
  date: new Date(service.date),
  type: service.type,
  description: service.description,
  mileage: service.mileage,
  cost: service.cost,
  location: service.location || '',
  nextServiceDate: new Date(service.next_service_date),
  attachments: service.attachments || []
});

const mapServiceToDB = (service: Omit<ServiceRecord, 'id'>): Omit<ServiceRecordRow, 'id' | 'user_id' | 'created_at'> => ({
  vehicle_id: service.vehicleId,
  date: service.date.toISOString(),
  type: service.type,
  description: service.description,
  mileage: service.mileage,
  cost: service.cost,
  location: service.location,
  next_service_date: service.nextServiceDate.toISOString(),
  attachments: service.attachments
});

interface ServiceStore {
  services: ServiceRecord[];
  loading: boolean;
  error: string | null;
  fetchServices: () => Promise<void>;
  addService: (service: Omit<ServiceRecord, 'id'>) => Promise<void>;
  updateService: (id: string, service: Partial<ServiceRecord>) => Promise<void>;
  deleteService: (id: string) => Promise<void>;
}

export const useServiceStore = create<ServiceStore>((set) => ({
  services: [],
  loading: false,
  error: null,

  fetchServices: async () => {
    set({ loading: true, error: null });
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No authenticated user');

      const { data, error } = await supabase
        .from('service_records')
        .select('*')
        .eq('user_id', user.id)
        .order('date', { ascending: false });

      if (error) throw error;
      set({ services: (data || []).map(mapServiceFromDB) });
    } catch (error) {
      set({ error: error instanceof Error ? error.message : 'An error occurred' });
    } finally {
      set({ loading: false });
    }
  },

  addService: async (service) => {
    set({ loading: true, error: null });
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No authenticated user');

      const { data, error } = await supabase
        .from('service_records')
        .insert([{ ...mapServiceToDB(service), user_id: user.id }])
        .select()
        .single();

      if (error) throw error;
      
      set(state => ({
        services: [mapServiceFromDB(data), ...state.services]
      }));
    } catch (error) {
      set({ error: error instanceof Error ? error.message : 'An error occurred' });
    } finally {
      set({ loading: false });
    }
  },

  updateService: async (id, service) => {
    set({ loading: true, error: null });
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No authenticated user');

      const { data, error } = await supabase
        .from('service_records')
        .update(mapServiceToDB(service as ServiceRecord))
        .eq('id', id)
        .eq('user_id', user.id)
        .select()
        .single();

      if (error) throw error;

      set(state => ({
        services: state.services.map(s => s.id === id ? mapServiceFromDB(data) : s)
      }));
    } catch (error) {
      set({ error: error instanceof Error ? error.message : 'An error occurred' });
    } finally {
      set({ loading: false });
    }
  },

  deleteService: async (id) => {
    set({ loading: true, error: null });
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No authenticated user');

      const { error } = await supabase
        .from('service_records')
        .delete()
        .eq('id', id)
        .eq('user_id', user.id);

      if (error) throw error;

      set(state => ({
        services: state.services.filter(s => s.id !== id)
      }));
    } catch (error) {
      set({ error: error instanceof Error ? error.message : 'An error occurred' });
    } finally {
      set({ loading: false });
    }
  }
}));