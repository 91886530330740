/* @author Jayden Scahill || Old Mate Developments */

import React from 'react';
import type { PersonalInfo } from '../../types/personal';
import { InfoSection } from './InfoSection';

interface PersonalInfoDisplayProps {
  info: PersonalInfo;
  onEdit: () => void;
}

export function PersonalInfoDisplay({ info, onEdit }: PersonalInfoDisplayProps) {
  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm">
      <div className="p-6 border-b border-gray-200 dark:border-gray-700 flex justify-between items-center">
        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
          Personal Details
        </h3>
        <button
          onClick={onEdit}
          className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
        >
          Edit Information
        </button>
      </div>

      <div className="p-6 grid grid-cols-1 md:grid-cols-2 gap-8">
        <InfoSection title="Basic Information">
          <div className="space-y-3">
            <p>
              <span className="font-medium">Name:</span>{' '}
              {info.firstName} {info.lastName}
            </p>
            <p>
              <span className="font-medium">Email:</span> {info.email}
            </p>
            <p>
              <span className="font-medium">Phone:</span> {info.phone}
            </p>
          </div>
        </InfoSection>

        <InfoSection title="Address">
          <div className="space-y-3">
            <p>{info.address.street}</p>
            <p>
              {info.address.city}, {info.address.state} {info.address.postalCode}
            </p>
            <p>{info.address.country}</p>
          </div>
        </InfoSection>

        <InfoSection title="Emergency Contact">
          <div className="space-y-3">
            <p>
              <span className="font-medium">Name:</span>{' '}
              {info.emergencyContact.name}
            </p>
            <p>
              <span className="font-medium">Relationship:</span>{' '}
              {info.emergencyContact.relationship}
            </p>
            <p>
              <span className="font-medium">Phone:</span>{' '}
              {info.emergencyContact.phone}
            </p>
          </div>
        </InfoSection>

        <InfoSection title="Driver's License">
          <div className="space-y-3">
            <p>
              <span className="font-medium">Number:</span>{' '}
              {info.driverLicense.number}
            </p>
            <p>
              <span className="font-medium">State:</span>{' '}
              {info.driverLicense.state}
            </p>
            <p>
              <span className="font-medium">Expires:</span>{' '}
              {new Date(info.driverLicense.expiryDate).toLocaleDateString()}
            </p>
          </div>
        </InfoSection>

        <InfoSection title="Insurance Information" className="md:col-span-2">
          <div className="space-y-3">
            <p>
              <span className="font-medium">Provider:</span>{' '}
              {info.insurance.provider}
            </p>
            <p>
              <span className="font-medium">Policy Number:</span>{' '}
              {info.insurance.policyNumber}
            </p>
            <p>
              <span className="font-medium">Expires:</span>{' '}
              {new Date(info.insurance.expiryDate).toLocaleDateString()}
            </p>
          </div>
        </InfoSection>
      </div>
    </div>
  );
}