/* @author Jayden Scahill || Old Mate Developments */

import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Upload } from 'lucide-react';
import { useServiceStore } from '../../store/serviceStore';
import { useVehicleStore } from '../../store/vehicleStore';
import type { ServiceRecord } from '../../types';

interface ServiceFormProps {
  service?: ServiceRecord;
  onComplete: () => void;
  isEditing?: boolean;
}

export function ServiceForm({ service, onComplete, isEditing = false }: ServiceFormProps) {
  const { addService, updateService } = useServiceStore();
  const { vehicles } = useVehicleStore();
  const [formData, setFormData] = useState<Partial<ServiceRecord>>(service || {});
  const [files, setFiles] = useState<File[]>([]);
  const [dateError, setDateError] = useState<string | null>(null);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFiles(prev => [...prev, ...acceptedFiles]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png'],
      'application/pdf': ['.pdf']
    }
  });

  const validateNextServiceDate = (date: string) => {
    const selectedDate = new Date(date);
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (selectedDate < today) {
      setDateError('Next service date must be in the future');
      return false;
    }
    setDateError(null);
    return true;
  };

  const handleNextServiceDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const date = e.target.value;
    if (validateNextServiceDate(date)) {
      setFormData({ ...formData, nextServiceDate: new Date(date) });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!formData.nextServiceDate || !validateNextServiceDate(formData.nextServiceDate.toISOString())) {
      return;
    }

    const attachments = files.map(file => ({
      id: crypto.randomUUID(),
      name: file.name,
      url: URL.createObjectURL(file),
      type: file.type
    }));

    const serviceData: Omit<ServiceRecord, 'id'> = {
      vehicleId: formData.vehicleId || '',
      date: new Date(formData.date as unknown as string),
      type: formData.type || '',
      description: formData.description || '',
      mileage: formData.mileage || 0,
      cost: formData.cost || 0,
      location: formData.location || '',
      nextServiceDate: new Date(formData.nextServiceDate as unknown as string),
      attachments: [...(formData.attachments || []), ...attachments]
    };

    if (isEditing && service) {
      await updateService(service.id, serviceData);
    } else {
      await addService(serviceData);
    }
    onComplete();
  };

  return (
    <form onSubmit={handleSubmit} className="form-container p-6 mb-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="md:col-span-2">
          <label className="form-label">Vehicle *</label>
          <select
            value={formData.vehicleId || ''}
            onChange={(e) => setFormData({ ...formData, vehicleId: e.target.value })}
            className="w-full rounded-lg"
            required
          >
            <option value="">Select a vehicle</option>
            {vehicles.map((vehicle) => (
              <option key={vehicle.id} value={vehicle.id}>
                {vehicle.year} {vehicle.make} {vehicle.model}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label className="form-label">Service Date *</label>
          <input
            type="date"
            value={formData.date instanceof Date ? formData.date.toISOString().split('T')[0] : ''}
            onChange={(e) => setFormData({ ...formData, date: new Date(e.target.value) })}
            className="w-full rounded-lg"
            required
          />
        </div>

        <div>
          <label className="form-label">Next Service Due *</label>
          <input
            type="date"
            value={formData.nextServiceDate instanceof Date ? formData.nextServiceDate.toISOString().split('T')[0] : ''}
            onChange={handleNextServiceDateChange}
            className={`w-full rounded-lg ${dateError ? 'border-red-500' : ''}`}
            required
          />
          {dateError && (
            <p className="text-sm text-red-500 mt-1">{dateError}</p>
          )}
        </div>

        <div>
          <label className="form-label">Current Kilometers/Hours *</label>
          <input
            type="number"
            value={formData.mileage || ''}
            onChange={(e) => setFormData({ ...formData, mileage: parseInt(e.target.value) })}
            className="w-full rounded-lg"
            required
          />
        </div>

        <div>
          <label className="form-label">Service Type *</label>
          <input
            type="text"
            value={formData.type || ''}
            onChange={(e) => setFormData({ ...formData, type: e.target.value })}
            className="w-full rounded-lg"
            required
          />
        </div>

        <div>
          <label className="form-label">Cost *</label>
          <input
            type="number"
            step="0.01"
            value={formData.cost || ''}
            onChange={(e) => setFormData({ ...formData, cost: parseFloat(e.target.value) })}
            className="w-full rounded-lg"
            required
          />
        </div>

        <div>
          <label className="form-label">Service Location</label>
          <input
            type="text"
            value={formData.location || ''}
            onChange={(e) => setFormData({ ...formData, location: e.target.value })}
            className="w-full rounded-lg"
          />
        </div>

        <div className="md:col-span-2">
          <label className="form-label">Description</label>
          <textarea
            value={formData.description || ''}
            onChange={(e) => setFormData({ ...formData, description: e.target.value })}
            rows={3}
            className="w-full rounded-lg"
          />
        </div>

        <div className="md:col-span-2">
          <label className="form-label">Documents & Receipts</label>
          <div
            {...getRootProps()}
            className={`border-2 border-dashed rounded-lg p-6 text-center cursor-pointer
              ${isDragActive 
                ? 'border-blue-500 bg-blue-50 dark:bg-blue-900/30' 
                : 'border-gray-300 dark:border-gray-600'}`}
          >
            <input {...getInputProps()} />
            <Upload className="w-8 h-8 mx-auto mb-2 text-gray-400" />
            <p className="text-sm text-gray-600 dark:text-gray-400">
              Drop files here, or click to select
            </p>
            <p className="text-xs text-gray-500 dark:text-gray-500 mt-1">
              Supported formats: JPEG, PNG, PDF
            </p>
          </div>
          
          {files.length > 0 && (
            <ul className="mt-4 space-y-2">
              {files.map((file) => (
                <li key={file.name} className="text-sm text-gray-600 dark:text-gray-400">
                  {file.name} ({(file.size / 1024).toFixed(1)} KB)
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>

      <div className="mt-6 flex justify-end gap-4">
        {isEditing && (
          <button
            type="button"
            onClick={onComplete}
            className="px-4 py-2 text-gray-700 dark:text-gray-200 border border-gray-300 dark:border-gray-600 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700"
          >
            Cancel
          </button>
        )}
        <button
          type="submit"
          className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
          disabled={!!dateError}
        >
          {isEditing ? 'Save Changes' : 'Add Service Record'}
        </button>
      </div>
    </form>
  );
}