/* @author Jayden Scahill || Old Mate Developments */

import React, { useState, useEffect } from 'react';
import { UserCircle } from 'lucide-react';
import { usePersonalStore } from '../../store/personalStore';
import { PersonalInfoForm } from './PersonalInfoForm';
import { PersonalInfoDisplay } from './PersonalInfoDisplay';
import type { PersonalInfo } from '../../types/personal';

export function PersonalInformation() {
  const { personalInfo, setPersonalInfo, updatePersonalInfo, fetchPersonalInfo } = usePersonalStore();
  const [isEditing, setIsEditing] = useState(!personalInfo);

  useEffect(() => {
    fetchPersonalInfo();
  }, [fetchPersonalInfo]);

  const handleSubmit = async (data: PersonalInfo) => {
    try {
      if (personalInfo) {
        await updatePersonalInfo(data);
      } else {
        await setPersonalInfo(data);
      }
      setIsEditing(false);
    } catch (error) {
      console.error('Error saving personal info:', error);
    }
  };

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold text-gray-900 dark:text-white flex items-center gap-2">
        <UserCircle className="w-6 h-6" />
        Personal Information
      </h2>

      {isEditing ? (
        <PersonalInfoForm
          initialData={personalInfo}
          onSubmit={handleSubmit}
          onCancel={personalInfo ? () => setIsEditing(false) : undefined}
        />
      ) : personalInfo ? (
        <PersonalInfoDisplay
          info={personalInfo}
          onEdit={() => setIsEditing(true)}
        />
      ) : null}
    </div>
  );
}