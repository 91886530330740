/* @author Jayden Scahill || Old Mate Developments */

import { format, addDays, differenceInDays } from 'date-fns';

export function formatDate(date: Date | string): string {
  return format(new Date(date), 'MMM dd, yyyy');
}

export function getDaysUntil(date: Date | string): number {
  return Math.ceil(
    differenceInDays(new Date(date), new Date())
  );
}

export function addDaysToDate(date: Date | string, days: number): Date {
  return addDays(new Date(date), days);
}