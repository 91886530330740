/* @author Jayden Scahill || Old Mate Developments */

import React from 'react';
import { useVehicleStore } from '../../store/vehicleStore';
import { useServiceStore } from '../../store/serviceStore';
import { ServiceStatus } from './ServiceStatus';

interface DashboardContentProps {
  onCardClick: (tab: string) => void;
}

export function DashboardContent({ onCardClick }: DashboardContentProps) {
  const { vehicles } = useVehicleStore();
  const { services } = useServiceStore();

  return (
    <div className="space-y-6">
      <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Dashboard</h1>
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <button
          onClick={() => onCardClick('vehicles')}
          className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow hover:shadow-md transition-all cursor-pointer text-left w-full"
        >
          <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">Vehicles</h2>
          <p className="text-3xl font-bold text-blue-500">{vehicles.length}</p>
        </button>
        
        <button
          onClick={() => onCardClick('services')}
          className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow hover:shadow-md transition-all cursor-pointer text-left w-full"
        >
          <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">Service Records</h2>
          <p className="text-3xl font-bold text-green-500">{services.length}</p>
        </button>
        
        <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow">
          <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">Total Cost</h2>
          <p className="text-3xl font-bold text-purple-500">
            ${services.reduce((sum, service) => sum + service.cost, 0).toFixed(2)}
          </p>
        </div>
      </div>

      <ServiceStatus />
    </div>
  );
}