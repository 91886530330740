/* @author Jayden Scahill || Old Mate Developments */

import { create } from 'zustand';
import { supabase } from '../lib/supabase';
import type { PersonalInfo } from '../types/personal';
import type { Database } from '../types/database';

type PersonalInfoRow = Database['public']['Tables']['personal_info']['Row'];

const mapPersonalFromDB = (data: PersonalInfoRow): PersonalInfo => ({
  id: data.id,
  firstName: data.first_name,
  lastName: data.last_name,
  email: data.email,
  phone: data.phone,
  address: {
    street: data.address.street,
    city: data.address.city,
    state: data.address.state,
    postalCode: data.address.postal_code,
    country: data.address.country,
  },
  emergencyContact: {
    name: data.emergency_contact.name,
    relationship: data.emergency_contact.relationship,
    phone: data.emergency_contact.phone,
  },
  driverLicense: {
    number: data.driver_license.number,
    expiryDate: data.driver_license.expiry_date,
    state: data.driver_license.state,
  },
  insurance: {
    provider: data.insurance.provider,
    policyNumber: data.insurance.policy_number,
    expiryDate: data.insurance.expiry_date,
  },
});

const mapPersonalToDB = (info: PersonalInfo): Omit<PersonalInfoRow, 'id' | 'user_id' | 'created_at' | 'updated_at'> => ({
  first_name: info.firstName,
  last_name: info.lastName,
  email: info.email,
  phone: info.phone,
  address: {
    street: info.address.street,
    city: info.address.city,
    state: info.address.state,
    postal_code: info.address.postalCode,
    country: info.address.country,
  },
  emergency_contact: {
    name: info.emergencyContact.name,
    relationship: info.emergencyContact.relationship,
    phone: info.emergencyContact.phone,
  },
  driver_license: {
    number: info.driverLicense.number,
    expiry_date: info.driverLicense.expiryDate,
    state: info.driverLicense.state,
  },
  insurance: {
    provider: info.insurance.provider,
    policy_number: info.insurance.policyNumber,
    expiry_date: info.insurance.expiryDate,
  },
});

interface PersonalStore {
  personalInfo: PersonalInfo | null;
  loading: boolean;
  error: string | null;
  fetchPersonalInfo: () => Promise<void>;
  setPersonalInfo: (info: PersonalInfo) => Promise<void>;
  updatePersonalInfo: (info: PersonalInfo) => Promise<void>;
}

export const usePersonalStore = create<PersonalStore>((set) => ({
  personalInfo: null,
  loading: false,
  error: null,

  fetchPersonalInfo: async () => {
    set({ loading: true, error: null });
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No authenticated user');

      const { data, error } = await supabase
        .from('personal_info')
        .select('*')
        .eq('user_id', user.id)
        .single();

      if (error && error.code !== 'PGRST116') throw error;
      set({ personalInfo: data ? mapPersonalFromDB(data) : null });
    } catch (error) {
      set({ error: error instanceof Error ? error.message : 'An error occurred' });
    } finally {
      set({ loading: false });
    }
  },

  setPersonalInfo: async (info) => {
    set({ loading: true, error: null });
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No authenticated user');

      const { data, error } = await supabase
        .from('personal_info')
        .upsert({ ...mapPersonalToDB(info), user_id: user.id })
        .select()
        .single();

      if (error) throw error;
      set({ personalInfo: mapPersonalFromDB(data) });
    } catch (error) {
      set({ error: error instanceof Error ? error.message : 'An error occurred' });
    } finally {
      set({ loading: false });
    }
  },

  updatePersonalInfo: async (info) => {
    set({ loading: true, error: null });
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No authenticated user');

      const { data, error } = await supabase
        .from('personal_info')
        .update(mapPersonalToDB(info))
        .eq('user_id', user.id)
        .select()
        .single();

      if (error) throw error;
      set({ personalInfo: mapPersonalFromDB(data) });
    } catch (error) {
      set({ error: error instanceof Error ? error.message : 'An error occurred' });
    } finally {
      set({ loading: false });
    }
  },
}));