/* @author Jayden Scahill || Old Mate Developments */

import React from 'react';
import { AuthForm } from './components/auth/AuthForm';
import { UpdatePassword } from './components/auth/UpdatePassword';
import { useAuth } from './hooks/useAuth';
import { Dashboard } from './components/Dashboard';

export default function App() {
  const { user, loading } = useAuth();

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50 dark:bg-gray-900">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  // Check if we're on the password reset route
  const isPasswordReset = window.location.hash.includes('/reset-password');
  if (isPasswordReset) {
    return <UpdatePassword />;
  }

  if (!user) {
    return <AuthForm />;
  }

  return <Dashboard />;
}