/* @author Jayden Scahill || Old Mate Developments */

import React from 'react';
import { LucideIcon } from 'lucide-react';

interface MobileTabProps {
  tab: {
    id: string;
    name: string;
    icon: LucideIcon;
  };
  active: boolean;
  onClick: () => void;
}

export function MobileTabButton({ tab, active, onClick }: MobileTabProps) {
  const Icon = tab.icon;
  return (
    <button
      onClick={onClick}
      className={`w-full flex items-center px-3 py-2 text-sm font-medium rounded-md transition-colors
        ${active
          ? 'bg-blue-50 dark:bg-blue-900/50 text-blue-600 dark:text-blue-400'
          : 'text-gray-500 dark:text-gray-400 hover:bg-gray-50 dark:hover:bg-gray-800'
        }`}
    >
      <Icon className="w-5 h-5 mr-2" />
      {tab.name}
    </button>
  );
}