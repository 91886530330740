/* @author Jayden Scahill || Old Mate Developments */

import React from 'react';
import { getDaysUntil } from '../../utils/date';
import { useServiceStore } from '../../store/serviceStore';
import { useVehicleStore } from '../../store/vehicleStore';

function getStatusColor(daysUntil: number) {
  if (daysUntil <= 10) {
    return 'bg-red-50 dark:bg-red-900/30 text-red-600 dark:text-red-400 border-red-200 dark:border-red-800';
  } else if (daysUntil <= 50) {
    return 'bg-yellow-50 dark:bg-yellow-900/30 text-yellow-600 dark:text-yellow-400 border-yellow-200 dark:border-yellow-800';
  }
  return 'bg-green-50 dark:bg-green-900/30 text-green-600 dark:text-green-400 border-green-200 dark:border-green-800';
}

export function ServiceStatus() {
  const { services } = useServiceStore();
  const { vehicles } = useVehicleStore();

  const upcomingServices = services
    .map(service => ({
      ...service,
      vehicle: vehicles.find(v => v.id === service.vehicleId),
      daysUntil: getDaysUntil(service.nextServiceDate)
    }))
    .filter(service => service.daysUntil > 0)
    .sort((a, b) => a.daysUntil - b.daysUntil)
    .slice(0, 5);

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow">
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
            Next Service Due
          </h3>
          {upcomingServices.length > 0 ? (
            <div className={`p-4 rounded-lg border ${getStatusColor(upcomingServices[0].daysUntil)}`}>
              <p className="font-medium">
                {upcomingServices[0].vehicle?.year} {upcomingServices[0].vehicle?.make} {upcomingServices[0].vehicle?.model}
              </p>
              <p className="text-sm mt-1">
                {upcomingServices[0].type} - Due in {upcomingServices[0].daysUntil} days
              </p>
            </div>
          ) : (
            <p className="text-gray-500 dark:text-gray-400">No upcoming services</p>
          )}
        </div>

        <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow">
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
            Upcoming Services
          </h3>
          <div className="space-y-3">
            {upcomingServices.slice(1).map(service => (
              <div
                key={service.id}
                className={`p-3 rounded-lg border ${getStatusColor(service.daysUntil)}`}
              >
                <p className="font-medium">
                  {service.vehicle?.year} {service.vehicle?.make} {service.vehicle?.model}
                </p>
                <p className="text-sm mt-1">
                  {service.type} - Due in {service.daysUntil} days
                </p>
              </div>
            ))}
            {upcomingServices.length <= 1 && (
              <p className="text-gray-500 dark:text-gray-400">No additional upcoming services</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}