/* @author Jayden Scahill || Old Mate Developments */

import { create } from 'zustand';
import { supabase } from '../lib/supabase';
import type { Vehicle } from '../types';
import type { Database } from '../types/database';

type VehicleRow = Database['public']['Tables']['vehicles']['Row'];

const mapVehicleFromDB = (vehicle: VehicleRow): Vehicle => ({
  id: vehicle.id,
  make: vehicle.make,
  model: vehicle.model,
  year: vehicle.year,
  vin: vehicle.vin,
  licensePlate: vehicle.license_plate,
  mileage: vehicle.mileage,
  purchaseDate: vehicle.purchase_date,
  insuranceInfo: vehicle.insurance_info,
  notes: vehicle.notes
});

const mapVehicleToDB = (vehicle: Omit<Vehicle, 'id'>): Omit<VehicleRow, 'id' | 'user_id' | 'created_at'> => ({
  make: vehicle.make,
  model: vehicle.model,
  year: vehicle.year,
  vin: vehicle.vin,
  license_plate: vehicle.licensePlate,
  mileage: vehicle.mileage,
  purchase_date: vehicle.purchaseDate,
  insurance_info: vehicle.insuranceInfo,
  notes: vehicle.notes
});

interface VehicleStore {
  vehicles: Vehicle[];
  loading: boolean;
  error: string | null;
  fetchVehicles: () => Promise<void>;
  addVehicle: (vehicle: Omit<Vehicle, 'id'>) => Promise<void>;
  updateVehicle: (id: string, vehicle: Partial<Vehicle>) => Promise<void>;
  deleteVehicle: (id: string) => Promise<void>;
}

export const useVehicleStore = create<VehicleStore>((set) => ({
  vehicles: [],
  loading: false,
  error: null,

  fetchVehicles: async () => {
    set({ loading: true, error: null });
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No authenticated user');

      const { data, error } = await supabase
        .from('vehicles')
        .select('*')
        .eq('user_id', user.id)
        .order('created_at', { ascending: false });

      if (error) throw error;
      set({ vehicles: (data || []).map(mapVehicleFromDB) });
    } catch (error) {
      set({ error: error instanceof Error ? error.message : 'An error occurred' });
    } finally {
      set({ loading: false });
    }
  },

  addVehicle: async (vehicle) => {
    set({ loading: true, error: null });
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No authenticated user');

      const { data, error } = await supabase
        .from('vehicles')
        .insert([{ ...mapVehicleToDB(vehicle), user_id: user.id }])
        .select()
        .single();

      if (error) throw error;
      
      set(state => ({
        vehicles: [mapVehicleFromDB(data), ...state.vehicles]
      }));
    } catch (error) {
      set({ error: error instanceof Error ? error.message : 'An error occurred' });
    } finally {
      set({ loading: false });
    }
  },

  updateVehicle: async (id, vehicle) => {
    set({ loading: true, error: null });
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No authenticated user');

      const { data, error } = await supabase
        .from('vehicles')
        .update(mapVehicleToDB(vehicle as Vehicle))
        .eq('id', id)
        .eq('user_id', user.id)
        .select()
        .single();

      if (error) throw error;

      set(state => ({
        vehicles: state.vehicles.map(v => v.id === id ? mapVehicleFromDB(data) : v)
      }));
    } catch (error) {
      set({ error: error instanceof Error ? error.message : 'An error occurred' });
    } finally {
      set({ loading: false });
    }
  },

  deleteVehicle: async (id) => {
    set({ loading: true, error: null });
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No authenticated user');

      const { error } = await supabase
        .from('vehicles')
        .delete()
        .eq('id', id)
        .eq('user_id', user.id);

      if (error) throw error;

      set(state => ({
        vehicles: state.vehicles.filter(v => v.id !== id)
      }));
    } catch (error) {
      set({ error: error instanceof Error ? error.message : 'An error occurred' });
    } finally {
      set({ loading: false });
    }
  }
}));