/* @author Jayden Scahill || Old Mate Developments */

import React, { useState } from 'react';
import { Wrench } from 'lucide-react';
import { useServiceStore } from '../store/serviceStore';
import { ServiceForm } from './services/ServiceForm';
import { ServiceList } from './services/ServiceList';

export function ServiceRecords() {
  const [showForm, setShowForm] = useState(false);

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold text-gray-900 dark:text-white flex items-center gap-2">
          <Wrench className="w-6 h-6" />
          Service History
        </h2>
        <button
          onClick={() => setShowForm(!showForm)}
          className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
        >
          {showForm ? 'Cancel' : 'Add Service Record'}
        </button>
      </div>

      {showForm && <ServiceForm onComplete={() => setShowForm(false)} />}
      <ServiceList />
    </div>
  );
}